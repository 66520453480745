import React from 'react';
import { graphql } from 'gatsby';
import Section from '../layout/Section.js';
import SEO from '../components/SEO.js';
import Hero from '../components/Hero.js';
import GridColumn from '../layout/Grid/GridColumn';
import GridContainer from '../layout/Grid/GridContainer';
import GridRow from '../layout/Grid/GridRow';
import IntroSection from '../components/IntroSection.js';
import { IconArrowRight, IconExternalLink2 } from '../components/Icons.js';
import Button from '../components/Button.js';

export default function MagazinPage({ data }) {
  return (
    <>
      <SEO title={data.page.seoTitle} description={data.page.seoDescription} />
      <Hero hero={data.page} />
      <Section>
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <IntroSection intro={data.page} />
              <p>
                <a
                  href="https://bicicli.de/magazin-radar"
                  target="_blank"
                  rel="noreferrer"
                  className="button mt-2"
                >
                  <IconExternalLink2 size="1.5" />
                  Zum Magazin »radar«
                </a>
              </p>
              <p>
                <a
                  href="https://bicicli.de/blog/"
                  target="_blank"
                  rel="noreferrer"
                  className="button mt-1"
                >
                  <IconExternalLink2 size="1.5" />
                  Zum BICICLI-Blog
                </a>
              </p>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
      <Section className="pt-0">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h2>MOND in den Medien</h2>
              <p>
                Hier finden Sie eine Auswahl von Beiträgen aus den Medien über
                die Arbeit und Projekte von MOND und BICICLI sowie eigene
                Kolumnen und wissenschaftliche Beiträge von uns.
              </p>
              <p>
                <Button to="/presse/" className="button mt-2 secondary">
                  Zu den Beiträgen
                </Button>
              </p>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
      <Section color="subtle05">
        <GridContainer>
          <GridRow>
            <GridColumn start="3" span="9">
              <h3 className="mt-0">Newsletter</h3>
              <p>
                Erfahren Sie regelmäßig Neues von uns über innovative Projekte
                und Produkte, Bewegendes aus der Mobilitätswelt und unsere
                Veranstaltungen und Services.
              </p>
              <a
                href={data.companyInfo.newsletterLink}
                className="button secondary mt-1"
                target="_blank"
                rel="noreferrer"
              >
                <IconArrowRight />
                Newsletter
              </a>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Section>
    </>
  );
}

export const query = graphql`
  query MagazinQuery {
    page: sanityMainPages(slug: { current: { eq: "magazin" } }) {
      headline
      seoTitle
      seoDescription
      _rawIntroCopy
      heroHeadline
      enableHero
      heroImage {
        alt
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      newsletterLink
    }
  }
`;
